<script setup lang="ts">
/**
 * @author Beka Chkhaidze
 * @note component inherits Input.vue's props and will be binded automatically
 * @summary you should pass items props array
 * @dataFlow is done via v-model
 *
 * {
 *  title: string;
 *  value: string;
 * }
 */
import { ref, watch } from "vue";


import type { SelectInterface } from "@/types/FormInterface";

import Dropdown from "@/components/regularComponents/UIComponents/Dropdown.vue";
import Input from "./Input.vue";
import DownChevron from "@/components/regularComponents/UIComponents/SIdeNavigation/SVG/DownChevron.vue";
import SlideUpDown from "vue3-slide-up-down";

const props = withDefaults(
  defineProps<{
    items?: SelectInterface[];
    label?: string;
    rightSlotText?: string;
    required?: boolean;
    modelValue?: SelectInterface | null;
    direction?: "bottom" | "top";
    customBody?: boolean;
    isDisabled?: boolean;
    useSlideUpDown?: boolean;
    noTranslate?: boolean;
  }>(),
  {
    SelectInterface: null,
    required: true,
    label: "Choose one",
    direction: "bottom",
    isDisabled: false,
    noTranslate: false,
  }
);

const emit = defineEmits<{
  (e: "select", item: SelectInterface | undefined): void;
  (e: "update:modelValue", item: SelectInterface): void;
  (e: "collapse-toggled", item: boolean): void;
}>();

const match = props?.items?.find((el) => el.value == props.modelValue?.value);

const isCollapsed = ref(false);
const selectedItem = ref<SelectInterface | undefined>(match);

const onClickOutside = () => {
  isCollapsed.value = false;
};

const onSelect = (item: SelectInterface) => {
  selectedItem.value = selectedItem.value?.value == item.value ? undefined : item;
  isCollapsed.value = false;
  emit("update:modelValue", item);
  emit("select", selectedItem.value);
};

watch(
  props,
  (n) => {
    if (!n?.modelValue) selectedItem.value = undefined;

    const match = props.items?.find((el) => el.isActive);
    if (match) {
      selectedItem.value = match;
    }
  },
  {
    immediate: true,
  }
);

watch(isCollapsed, (n) => {
  emit("collapse-toggled", n);
});
</script>

<template>
  <div
    :class="[
      'custom-select',
      { 'custom-select--collapsed': isCollapsed },
      { 'custom-select--custom-body': customBody },
      { 'custom-select--slide-up-down': useSlideUpDown },
      { 'custom-select--disabled': isDisabled },
    ]"
    v-click-outside="onClickOutside"
  >
    <Input
      v-bind="{
        ...$attrs,
        label,
        inputDisabled: true,
      }"
      @click="isCollapsed = !isCollapsed"
      :noTranslate="noTranslate"
    >
      <template v-if="selectedItem || modelValue" #main>
        {{ modelValue?.title }}</template
      >
      <template #right>
        <p class="text" v-if="rightSlotText">{{ rightSlotText }}</p>
        <DownChevron />
      </template>
    </Input>
    <Dropdown
      v-if="customBody && !useSlideUpDown"
      :autoOpen="false"
      :active="isCollapsed"
    >
      <template #body>
        <slot name="custom" />
      </template>
    </Dropdown>
    <template v-if="useSlideUpDown">
      <SlideUpDown v-model="isCollapsed">
        <template v-if="customBody">
          <slot name="custom" />
        </template>

        <template v-else>
          <ul v-if="items?.length" class="list text-left">
            <li
              v-for="(item, index) in items"
              :key="index"
              @click="onSelect(item)"
              :class="[
                'custom-select__item',
                {
                  'custom-select__item--active': selectedItem?.value == item.value,
                },
              ]"
            >
              {{ item?.title }}
            </li>
          </ul>
        </template>
      </SlideUpDown>
    </template>
    <Dropdown
      :active="isCollapsed"
      :direction="direction"
      hideIcon
      v-else-if="!useSlideUpDown && !customBody"
    >
      <template #body>
        <ul v-if="items?.length" class="list text-left">
          <li
            v-for="(item, index) in items"
            :key="index"
            @click="onSelect(item)"
            :class="[
              'custom-select__item',
              {
                'custom-select__item--active': selectedItem?.value == item.value,
              },
            ]"
          >
            {{ item?.title }}
          </li>
        </ul>
        <p v-else class="custom-select__item op-3 ev-none text-center">
          {{ $t("list is empty") }}
        </p>
      </template>
    </Dropdown>
  </div>
</template>

<style lang="scss">
.custom-select {
  @include easeOut(500, opacity);
  .custom-input {
    &.has-slot {
      .custom-input__input-label {
        top: 0;
        transform: unset;

        font-size: 28px;

        @include maxq(desktop-2k) {
          font-size: 16px;
        }

        @include maxq(desktop-lg) {
          font-size: 12px;
        }
      }
    }

    &__input {
      position: relative;

      &-label {
        cursor: pointer;
      }
    }

    &__value {
      bottom: 0 !important;
      position: absolute !important;
      pointer-events: none;
    }

    &__content,
    input {
      cursor: pointer;
    }

    &__content {
      --padding-content: 34px 31px;

      @include maxq(desktop-2k) {
        --padding-content: 16px;
      }

      .custom-input__default-slot {
        font-size: 20px;

        @include maxq(desktop-2k) {
          font-size: 16px;
        }
      }
      &.is-focused {
        .custom-input {
          &__right-slot {
            .icon {
              transform: rotate(180deg);
            }
          }
        }

        input {
          caret-color: transparent;
        }
      }

      .custom-input__default-slot {
        display: flex;
        align-items: flex-end;
      }
    }

    &__right-slot {
      @include easeInOut(350ms, transform);

      .text {
        margin-right: 4px;
      }
    }
  }

  .custom-dropdown {
    padding: 0;

    .list {
      max-height: 400px;
      overflow-y: auto;
      padding-right: 5px;
      @include scrollbar;
    }

    .drop-content {
      width: 100%;

      &-in {
        padding: 0;
        padding-right: 5px;
      }
    }
  }

  &__item {
    font-size: 25px;
    padding: 24px 20px;
    line-height: 34px;
    color: $darkBlue;

    @include maxq(desktop-2k) {
      padding: 22px 18px;
      font-size: 18px;
      line-height: 22px;
    }

    @include maxq(desktop-lg) {
      padding: 20px 16px;
      font-size: 15px;
      line-height: 20px;
    }

    @include hover {
      background-color: $cultured;
    }

    &--active {
      background-color: $cultured;
    }
  }

  &--custom-body {
    .drop-content {
      width: unset !important;
    }
  }
  &--collapsed {
    .custom-input__right-slot {
      transform: rotate(180deg);
    }
  }
  &--disabled {
    opacity: 0.6;
    pointer-events: none;
  }
  .price-range-select {
    .custom-dropdown .drop-content {
      right: -100%;
    }
  }
}
</style>
